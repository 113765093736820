import { Section } from "@components/UI-Elements/General";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import Layout from "../components/layout";
import NewsApiList from "../components/Modules/news/newsapi";
import SEO from "../components/seo";

const Page: React.FC = () => {
  const keywords = ["bitcoin"]; // "metaverse"

  return (
    <Layout>
      <SEO keywords={['aktuelle', 'news']} title="Aktuelle News" robots="noindex" />
      <Title>Aktuelle News</Title>
      {keywords.map((keyword) => (
        <Section key={keyword}>
          <div>
            <NewsApiList keyword={keyword} />
          </div>
        </Section>
      ))}
    </Layout>
  );
};

export default Page;
